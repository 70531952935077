<template>

  <div class="wrapper">
    <!--         知识付费落地页-->

    <headBack>
      <template v-slot:title>
        <div class='title'>
          详情
        </div>
      </template>
    </headBack>
    <div class='header_cont'></div>
    <!--    <div class="top">-->
    <!--      <div class="left">-->
    <!--        <img src="https://static.yihu365.cn/img/wx-uni/logo_kts.png" alt="">-->
    <!--      </div>-->
    <!--      <div class="center">-->
    <!--        <div>医护到家</div>-->
    <!--        <div class="bo_t">下载医护到家，了解更多健康知识</div>-->
    <!--      </div>-->
    <!--      <div class="right" @click="download">-->
    <!--        去下载-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div class="f_height"></div>-->
    <div class="main">
      <div class="banner">
<!--        <img :src="detail.HEAD_PIC_URL" alt="">-->
        <video :src="videoSrc"  class="video-box" controls :poster="detail.HEAD_PIC_URL" ref="videoMedia">

<!--          <source :src="require(videoSrc)" type="video/mp4">-->
        </video>
      </div>
      <div class="bti">
        <div class="tit">{{ detail.DESCRIPTION }}</div>
        <div class="sub" v-if="detail.productItemList">共{{detail.productItemList.length}}课时 ｜ {{detail.SALE_NUM}}人学习 </div>
      </div>
      <div class="kc_box">
        <van-tabs v-model:active="activeName">
          <van-tab title="课程介绍" name="a">
            <div class="jies">
              <div class="b_ti">
                <span class="k_u"></span>
                <span>课程介绍</span>
              </div>
              <img v-for="(item,index) in detail.INFO_URL" class="j_img" :src="item" alt="">
              <div class="b_ti">
                <span class="k_u"></span>
                <span>课程目录</span>
              </div>
              <div class="ml_list">
                <div class="list" v-for="(item,index) in detail.productItemList" @click="clickItem(item)">
                  <div class="txt">
                    <div class="left-v" :style="{background:`url(${detail.HEAD_PIC_URL}) no-repeat 0 0`}">
                      <img src="https://static.yihu365.cn/img/h5Img/video_icon.png" alt="">
                    </div>

                    <div class="right-v">
                      <div class="bti2">{{item.ITEM_NAME}}</div>
                      <div>{{item.DURATION}}</div>
                    </div>
                  </div>
                  <van-divider />
                </div>
              </div>
            </div>
          </van-tab>
          <van-tab title="课程目录" name="b">
            <div class="jies">
              <div class="b_ti">
                <span class="k_u"></span>
                <span>课程目录</span>
              </div>
              <div class="ml_list">
                <div class="list" v-for="(item,index) in detail.productItemList" @click="clickItem(item)">
                  <div class="txt">
                    <div class="left-v" :style="{background:`url(${detail.HEAD_PIC_URL}) no-repeat 0 0`}">
                      <img src="https://static.yihu365.cn/img/h5Img/video_icon.png" alt="">
                    </div>

                    <div class="right-v">
                      <div class="bti2">{{item.ITEM_NAME}}</div>
                      <div>{{item.DURATION}}</div>
                    </div>
                  </div>
                  <van-divider />
                </div>
              </div>
            </div>
          </van-tab>

        </van-tabs>
      </div>
    </div>


  </div>
</template>
<script>
import {setWxShareDate} from '@/libs/utils.js'
import {reactive,ref,onMounted} from 'vue';
import homeApi from '@axios/home'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
export default {
  name: "detail",
  setup () {
    let router = useRouter()
    const store = useStore()
    const route = useRoute()




    const videoSrc = ref('');
    const detail = ref('');


    onMounted(()=> {
      getDetail();
    })
    const getDetail = () => {
      let formData = new FormData();
      formData.append('data', JSON.stringify({
        function: "activityProductItemInfo",
        productId:route.query.productId,
        // userId:store.state.uid,
        newversion:'2120'
      }))
      formData.append('encryption', false)

      homeApi.getList(formData).then((res)=> {
        console.log('1',res.data);
        detail.value = res.data;
        videoSrc.value = res.data.productItemList[0].SERVICE_INFO_URL

        console.log( videoSrc.value )
        setWxShareDate(`医护到家：${res.data.NAME}`,res.data.HEAD_PIC_URL)//分享

      })
    }


    const info = ref('')
    const getInfo = () => {
      const params ={
        productId : route.query.productId
      }
      homeApi.getActivityProductItemInfo(params).then((res)=> {
        console.log(res.data)
        info.value = res.data
        // setWxShareDate(info.value.NAME,'https://static.yihu365.cn/img/wx-uni/logo_kts.png');// 分享
      })

    }
    getInfo();

    const videoMedia = ref(null)
    const clickItem=(item)=> {

      console.log(item)
       videoSrc.value = item.SERVICE_INFO_URL;
      setTimeout(()=> {
        videoMedia.value.play()
      },100)
      window.scrollTo(0,0)
    }

    return {
      getDetail,
      info,
      detail,
      videoSrc,
      clickItem,
      videoMedia
    }
  }
}
</script>

<style scoped lang="scss">
.sss{

}
::v-deep(.van-tabs__line){
  background-color:#00C291;
}
::v-deep(.van-tab--active){
  color:#00C291;
  font-weight: bold;
}
.wrapper{
  background: #F2F2F2;

  font-family: STHeiti,Microsoft YaHei,Helvetica,Arial,sans-serif;
}
.top{
  background: #FFF0F0;
  width: 100%;
  height:110px;
  display: flex;
  font-size: 26px;
  box-sizing: border-box;
  align-items: center;
  position: fixed;
  left: 0;
  top:0;
  z-index: 100;
  color:#EE5254;
  box-shadow:0px 15px 20px -12px #FFF0F0;

  .left{
    img{
      width: 70px;
      height: 70px;
      margin:0 28px;
    }

  }
  .center{
    font-weight: bold;
    white-space: nowrap;
    flex-grow: 1;
    .bo_t{
      font-size: 25px;
      font-weight: normal;
    }
  }
  .right{
    width:90px;
    font-weight: bold;
    font-size: 30px;
    padding:0 60px;
    text-align: center;
    color:#fff;
    line-height: 80px;
    border-radius: 40px;
    background: #00C291;
    margin-right: 10px;
    white-space: nowrap;
  }
}
.f_height{
  width: 100%;
  height:110px;
}
.main{
  .banner{
    box-shadow:0px 20px 10px 0px rgba(0,0,0,0.5);
    img{
      width: 100%;
    }
    .video-box{
      width: 100%;
    }
  }
  .bti{
    background: #fff;
    box-sizing: border-box;
    padding:20px 40px 26px;
    .tit{
      color:#434343;
      font-size: 36px;
      font-weight: bold;
    }
    .sub{
      margin-top: 20px;
      color:#999;
      font-size: 26px;
    }
  }
  .audio_box{
    padding:20px 0;
    width: 96%;
    margin:16px auto;
    background: #FFFFFF;
    border-radius: 14px;
    font-size: 26px;
    color:#434343;
    .audio{

      box-sizing: border-box;
      padding:10px 20px;
      color: #919191;
      .slider{
        display: flex;
        justify-content: space-between;
        align-items: center;
        ::v-deep(.van-slider__button){
          width: 22px;
          height: 22px;
          background: #00C291;
        }
        ::v-deep(.van-slider__bar){
          background: #00C291;
        }
        ::v-deep(.van-slider){
          width:480px;
          margin:0 15px;
        }
      }
      .play_box{

        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 22px;
        .l_p,.r_p{
          width: 40px;
          height: 40px;
        }
        .c_p{
          width: 100px;
          height: 100px;
          margin:0 90px;
        }
        .r_p{}
      }
    }

    audio{
      display: none;
      margin-left: 20px;
      margin-bottom: 20px;
    }
    .a_tit{
      box-sizing: border-box;
      padding:10px 10px 10px 20px;
      .st{
        background: #00C291;
        border-radius: 18px;
        display: inline-block;
        color:#fff;
        padding:3px 8px;
        margin-right: 10px;
      }
    }
  }
  .kc_box{
    background: #FFFFFF;
    .jies{
      font-size: 0;
      .b_ti{
        padding:20px 30px;
        font-size: 28px;
        font-weight: bold;
        .k_u{
          display: inline-block;
          vertical-align: middle;
          width: 18px;
          height: 36px;
          background:#00C291 ;
          margin-right: 10px;
        }
      }
      .j_img{
        width: 100%;
        font-size: 0;
      }
      .ml_list{
        box-sizing: border-box;
        padding:10px 30px 0px;
        font-size: 24px;
        .list{
          .txt{
            color:#434343;
            display: flex;
            padding-left: 20px;
            box-sizing: border-box;
          }
          .left-v{
            width: 238px;
            height: 122px;
            border-radius: 8px;
            background-size: 100% !important;
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            margin-right: 20px;
            //flex-grow: 0;
            flex:0 0 auto;
            img{
              width: 50px;
              margin: 0 8px 8px 0;
            }
          }
          .right-v{
            box-sizing: border-box;
            padding:2px 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            font-size: 26px;
            color:#999999;
            .bti2{
              font-size: 32px;
              color:#323232;
            }
          }

        }
        ::v-deep(.van-divider){
          margin:24px 0;
        }
      }
    }
  }

}
.bottom_fix{
  position: fixed;
  left:0;
  bottom:0;
  z-index: 100;
  height:120px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 30px;

  box-shadow:0px -15px 20px -12px #FFF0F0;
  box-sizing: border-box;
  padding:0 30px;
  p{
    margin:0;
  }
  .sub{
    font-size: 28px;
    margin-top: 6px;
  }
  .btn{
    color:#fff;
    padding: 12px 40px;
    border-radius: 30px;
  }
  .price{
    color:#fff;
    font-size: 40px;
    .yu{
      font-size: 32px;
    }
  }
  .yj{
    font-size: 24px;
    display: inline-block;
    background-color: #FFFFFF;
    border-radius: 20px;
    margin-left: 24px;
    padding:2px 16px;
  }

}
</style>
